import { useQuery } from '@apollo/client'
import Container from 'components/Container'
import dayjs from 'dayjs'
import { GET_MENU } from 'gql/page'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { Page } from 'types/page'
import { useSettings } from '../context/SettingsProvider/SettingsContext'

type FooterLink = {
  title: string
  href: string
  ext?: boolean
}
const FooterLink = ({ link }: { link: FooterLink }) => (
  <li key={link.href}>
    <Link href={link.href}>
      <a
        target={link.ext ? '_blank' : undefined}
        rel={link.ext ? 'noreferrer' : undefined}
        className='hover:text-purple-heart'
      >
        {link.title}
      </a>
    </Link>
  </li>
)

const Footer = () => {
  const { t } = useTranslation()
  const { data: menuData } = useQuery<{ menu: Array<Page> }>(GET_MENU)
  const setting = useSettings()

  const linksCol1 = [
    {
      title: t('Dashboard'),
      href: '/',
    },
    ...(menuData?.menu || []).map((page) => ({
      title: page.title,
      href: `/${page.slug}`,
    })),
  ]

  const linksCol2 = [
    {
      title: t('Terms & Conditions'),
      href: setting('app.terms-conditions', 'https://pixelfusion.co.nz'),
      ext: true,
    },
    {
      title: t('Privacy Policy'),
      href: setting('app.privacy-policy', 'https://pixelfusion.co.nz'),
      ext: true,
    },
    {
      title: t('Code of Conduct'),
      href: setting('app.code-of-conduct', 'https://pixelfusion.co.nz'),
      ext: true,
    },
  ]

  return (
    <footer className='pt-8 md:pt-16 pb-14 border-t border-gray-100 print:hidden'>
      <Container className='md:flex'>
        <div className='mt-3 grid grid-cols-2 gap-4 md:mt-0 md:gap-8'>
          <div>
            <h4 className='uppercase my-3 md:mt-0 font-bold font-heading'>
              {t('Your learning')}
            </h4>
            <ul className='space-y-3'>
              {linksCol1.map((link) => (
                <FooterLink key={`${link.href}-${link.title}`} link={link} />
              ))}
            </ul>
          </div>
          <div>
            <h4 className='uppercase my-3 md:mt-0 font-bold font-heading'>
              {t('Policies')}
            </h4>
            <ul className='space-y-3'>
              {linksCol2.map((link) => (
                <FooterLink key={`${link.href}-${link.title}`} link={link} />
              ))}
            </ul>
          </div>
        </div>
        <div className='text-center text-gray-600 mt-10 md:mt-0 md:ml-auto md:text-right md:flex md:flex-col'>
          <div>
            &copy;{dayjs(new Date()).format('YYYY')}{' '}
            {setting('app.name', 'nameHere')}
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
